// third-party
import { combineReducers } from "redux";

// project import
import menu from "./menu";
import dashboard from "./dashboard";
import geral from "./geral";

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  geral,
  dashboard,
});

export default reducers;
