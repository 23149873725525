import { createSlice } from "@reduxjs/toolkit";
import Moment from "moment";
const initialState = {
  filtros: {
    status: "todos",
    dataInicial: Moment().subtract(1, "month").toDate(),
    dataFinal: new Date(),
    pesquisa: "",
  },
};
const dashboard = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setFiltros(state, action) {
      state.filtros = action.payload;
    },

    resetDashboard(state) {
      state.filtros = initialState.filtros;
    },
  },
});

export default dashboard.reducer;

export const { setFiltros, resetDashboard } = dashboard.actions;
