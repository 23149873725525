import { lazy } from "react";

import Loadable from "@/components/Loadable";
import MainLayout from "@/layout/MainLayout";

const DashboardDefault = Loadable(lazy(() => import("@/pages/dashboard")));

const Estatisticas = Loadable(
  lazy(() => import("@/pages/estatisticas-gerais"))
);
const DadosGerais = Loadable(lazy(() => import("@/pages/dados-gerais")));

const MainRoutes = {
  path: "",
  element: <MainLayout />,
  children: [
    {
      path: "/:token",
      element: <DashboardDefault />,
    },

    {
      path: "dados-gerais",
      element: <DadosGerais />,
    },
    {
      path: "estatisticas",
      element: <Estatisticas />,
    },
  ],
};

export default MainRoutes;
