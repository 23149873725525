import { Box, useMediaQuery } from "@mui/material";

import LogoBranco from "@/components/LogoBranco";
import Profile from "./Profile";
import MobileSection from "./MobileSection";

const HeaderContent = () => {
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <>
      {!matchesXs && (
        <Box sx={{ width: "100%", ml: { xs: 0, md: 1 } }}>
          <a href="/area-admin">
            <LogoBranco style={{ height: "35px", paddingLeft: "1rem" }} />
          </a>
        </Box>
      )}
      {matchesXs && <Box sx={{ width: "100%", ml: 1 }} />}

      {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />}
    </>
  );
};

export default HeaderContent;
