import * as React from "react";
import { useState, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useDispatch } from "react-redux";
import { setInfoToaster } from "@/store/reducers/geral";
export default function Toaster({ infoToaster }) {
  const dispatch = useDispatch();
  const [criouTimeout, setCriouTimeout] = useState(false);
  useEffect(() => {
    if (infoToaster.open && !criouTimeout) {
      setCriouTimeout(true);
      setTimeout(() => {
        dispatch(setInfoToaster({ open: false }));
      }, infoToaster.timeout || 5000);
    }
  }, [infoToaster, dispatch, criouTimeout]);
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  return (
    <Snackbar
      open={infoToaster.open}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      autoHideDuration={60}
      onClick={() => {
        dispatch(setInfoToaster({ open: false }));
      }}
    >
      <Alert severity={infoToaster.type} sx={{ width: "100%" }}>
        {infoToaster.message}
      </Alert>
    </Snackbar>
  );
}
